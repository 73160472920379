<template>
  <div class='flex flex-col items-stretch lg:flex-row h-screen'>
    <div
      class='w-full lg:w-96 px-4 lg:px-16 py-4 lg:py-8 text-gray-50 flex flex-col items-start justify-start'
      :style='backgroundStyle'>
      <h1
        class='text-normal font-light border border-solid border-transparent py-1 pl-1 pr-4 rounded-md hover:border-gray-50 cursor-pointer'
        @click='goBack'>
        <arrow-narrow-left-icon class='h-4 inline-block' />
        Back
      </h1>
      <h1 class='text-xl lg:text-3xl font-light mt-4 lg:mt-8'>사전등록</h1>
    </div>
    <div class='p-8 lg:p-16 overflow-y-auto h-screen flex-grow'>
      <div class='w-full max-w-2xl'>
        <div class='mb-12'>
          <img 
            :src='eventConfigLogoImageUrl'
            style='max-height: 5rem;' />
        </div>
        <div class='mb-6'>
          <label class='uppercase text-sm text-gray-500 mb-2'>성함</label>
          <input
            v-model='profile.name'
            class='border py-2 px-2 rounded block w-full'
            type='text'
            placeholder='성함'>
        </div>
        <div class='mb-6'>
          <label class='uppercase text-sm text-gray-500 mb-2'>휴대전화번호</label>
          <input
            v-model='profile.phoneNumber'
            class='border py-2 px-2 rounded block w-full'
            type='text'
            placeholder='휴대전화번호'>
        </div>
        <div class='mb-6'>
          <label class='uppercase text-sm text-gray-500 mb-2'>병원명</label>
          <input
            v-model='profile.hospitalName'
            class='border py-2 px-2 rounded block w-full'
            type='text'
            placeholder='병원명'>
        </div>
        <div class='mb-6'>
          <label class='uppercase text-sm text-gray-500 mb-2'>진료과</label>
          <input
            v-model='profile.department'
            class='border py-2 px-2 rounded block w-full'
            type='text'
            placeholder='진료과'>
        </div>
        <div class='mb-6'>
          <label class='uppercase text-sm text-gray-500 mb-2'>이메일 주소</label>
          <input
            v-model='signupParams.email'
            class='border py-2 px-2 rounded block w-full'
            type='text'
            placeholder='이메일 주소'>
        </div>


        <div class='text-sm border rounded-md mb-8'>
          <div class='bg-gray-100 p-4'>
            <p class='my-2'>한국아스트라제네카(이하"회사")는 귀하로부터 [성함, 병원명, 연락처 등] 개인정보를 수집합니다.</p>
            <p class='my-2'>위 정보는 회사가 주최하는 “ABSOLUTE Symposium" (이하 "행사") 의 진행 및 관련 활동의 수행을 위하여 수집 및 이용 됩니다.</p>
            <p class='my-2'>회사는 이와 같은 수집 및 이용 목적을 달성할 때까지 귀하의 개인 정보를 보유 및 이용 합니다. 귀하는 위와 같은 개인 정보의 수집 및 이용을 거부할 수 있습니다.</p>
            <p class='my-2'>다만 본 개인정보의 수집 및 이용에 동의하지 않으실 경우 본 행사 참석에 어려움이 발생 할 수 있음을 알려 드립니다. 또한 회사는 행사의 원활한 진행을 위하여 [(주)한국아스트라제네카]에 위에 열거한 귀하의 개인정보를 제공할 수 있으며 [㈜한국아스트라제네카] 는 행사 진행을 위한 개인 정보를 이용 목적을 달성할때까지 해당 정보를 보유 및 이용 합니다.</p>
          </div>
          <label class='border-t p-4 flex flex-row justify-start items-center gap-x-0.5 text-normal font-normal'>
            <input type='checkbox' v-model='extraInfo.disclaimer'>
            <span class='px-1'> 필수) 개인정보의 수집 및 이용에 대한 동의합니다.</span>
          </label>
        </div>
        <div class='text-sm border rounded-md mb-8'>
          <label class='border-t p-4 flex flex-row justify-start items-center gap-x-0.5 text-normal font-normal'>
            <input type='checkbox' v-model='extraInfo.iAmADoctor'>
            <span class='px-1'> 필수) 본인은 보건의료전문가임을 확인합니다.</span>
          </label>
        </div>
        <ul class='mt-8 mb-6 list-disc list-inside text-red-600 text-xs text-left'>
          <li v-if='emptyName'>이름을 입력하세요</li>
          <li v-if='emptyHospitalName'>병원명를 입력하세요</li>
          <li v-if='emptyDepartment'>진료과를 입력하세요</li>
          <li v-if='!validEmail'>E-mail 주소 형식으로 입력하세요</li>
          <li v-if='emptyMobile'>핸드폰번호를 입력하세요</li>
          <li v-if='!extraInfo.disclaimer'>개인정보 수집 및 제공에 동의해주세요</li>
          <li v-if='!extraInfo.iAmADoctor'>보건의료전문가 확인을 체크해주세요</li>
        </ul>
        <button
          :disabled='disabledSignupButton'
          @click='submitSignup'
          class='border py-3 px-8 text-center text-white text-sm uppercase w-full bg-gray-900 block rounded-md'
          :class='disabledSignupButtonClass'>
          등록하기
        </button>
        <p class='mt-4 text-gray-500 text-xs p-4'>
          한국아스트라제네카에서 제공하는 컨텐츠 다운로드 및 캡쳐 등 무단 배포 및 외부로의 유출 행위를 금지합니다. 본 심포지엄은 과학적인 정보 제공 및 교육을 목적으로 시행 됩니다. 본 초대 및 관련 제공은 적절한 참석 및 진행을 위해 구성되며, 이외의 목적이 아님을 알려 드립니다. 또한 참석 하시는 선생님 외 개인적인 동행자의 참석은 어려우니 양해 부탁 드립니다.
        </p>
      </div>
    </div>

  </div>

</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { mapFields }           from 'vuex-map-fields'
import { ArrowNarrowLeftIcon } from 'vue-tabler-icons'

export default {
  name: 'Signup',
  components: {
    ArrowNarrowLeftIcon,
  },
  computed: {
    ...mapFields('users', [
      'signupParams',
      'profile.extraInfo',
      'profile',
    ]),
    ...mapState('users', [
      'hasValidToken',
    ]),
    ...mapGetters('events', [
      'eventMainThemeColor',
      'eventConfigLogoImageUrl',
    ]),
    backgroundStyle () {
      return `background-color: ${this.eventMainThemeColor};`
    },
    emptyEmailField () {
      return !this.signupParams.email
    },
    emptyPasswordField () {
      return !this.signupParams.password || 
             !this.signupParams.passwordConfirmation
    },
    emptyName () {
      return !this.profile.name
    },
    emptyHospitalName () {
      return !this.profile.hospitalName
    },
    emptyDepartment () {
      return !this.profile.department
    },
    emptyMobile () {
      return !this.profile.phoneNumber || this.profile.phoneNumber.length < 6
    },
    validEmail () {
      let mailformat = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g
      return mailformat.test(this.signupParams.email)
    },
    disabledSignupButton () {
      return this.emptyEmailField || !this.validEmail ||
             this.emptyName || 
             this.emptyMobile ||
             this.emptyDepartment ||
             this.emptyHospitalName ||
             !this.extraInfo.disclaimer ||
             !this.extraInfo.iAmADoctor 


    },
    disabledSignupButtonClass () {
      return this.disabledSignupButton ? 'opacity-50' : ''
    }
  },
  watch: {
    'profile.name': {
      handler: function (newVal) {
        this.signupParams.password = newVal
        this.signupParams.confirmPassword = newVal
      }
    }
  },
  methods: {
    ...mapActions('users', [
      'signup',
      'createProfile',
    ]),
    ...mapActions('users', [
      'checkTokenStatus',
    ]),
    goBack () {
      this.$router.push({name: 'Home'}).catch(() => {})
    },
    redirectAfterAction () {
      let redirectRoute = this.$route.query.redirect_route ? this.$route.query.redirect_route : 'Home'
      this.$router.push({name: redirectRoute})
    },
    submitSignup () {
      if (this.hasValidToken) {
        this.createProfile() // will update if exists
      } else {
        this.signup().then(() => {
          this.createProfile().then(() => {
            this.$router.push({ name: 'SignupConfirmation' })
          })
        }).catch((error) => {
          if (error.response.status === 409) {
            this.$alert('이미 등록된 이메일입니다. 이메일을 확인 후 다시 등록해주세요.', {
              confirmButtonText: '확인',
              type: 'warning'
            })   
          } else {
            console.error(error)
          }
        })
      }
    },
  },
  created () {
    this.checkTokenStatus().then(() => {
      if (this.hasValidToken) {
        this.redirectAfterAction()
      }
    })
  },
  mounted () {
    this.$emit('update:layout', 'empty-page')
  },
  beforeDestroy () {
    this.$emit('update:layout', 'default-layout')
  },
}
</script>
